$(window).scroll(function() {
      if ($(document).scrollTop() > 10) {
        $(".custmHdr").css("background","linear-gradient(90deg, rgba(69,38,120,1) 35%, rgba(101,52,180,1) 100%)");
        $(".custmHdr").css("padding","20px 0");
        $(".custmHdr2").css("background","");
      } else {
        $(".custmHdr").css("background","transparent");
        $(".custmHdr").css("padding","60px 0");    
        $(".custmHdr2").css("background","#6637C3");
      }
    }); 



$('.bxslider').bxSlider({
	auto:true,
    pager:false,
    preloadImages:true,
    mode:'fade',
    captions:false,
    speed:2000,
    pause:7000,
    hideControlOnEnd:true,
    infiniteLoop:true,
    controls:true,
    touchEnabled:false,
});

$('#featuresSlider').owlCarousel({
    loop:true,
    nav:true,
    navText: ["<img class='img-fluid' src='images/left-arrow.png'>","<img class='img-fluid' src='images/right-arrow.png'>"],
    dots:false,
    items:4,
    autoplay:true,
    autoplayTimeout:8000,
    margin:10,
    smartSpeed:3000,
    responsive:
        {
          0:{
              items:1
          },
          600:{
              items:2
          },
          1000:{
              items:4
          }
      }
})

